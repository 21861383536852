import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { addVehiclesFromCSV } from '../../../api';
import AuthenticatedPage from '../../../Components/AuthenticatedPage';
import { getBatteryTypes } from '../../../state/battery_types';
import { getVehicleTypes } from '../../../state/vehicle_types';
import { getCommTypes } from '../../../state/comm_types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import download from 'downloadjs';

const AddVehicles = ({ actions, battery_types = [], vehicle_types = [], comm_types = [] }) => {
  const [vehicle_type, setVehicle_type] = useState(1);
  const [comm_type, setComm_type] = useState(1);
  const [battery_type, setBattery_type] = useState(1);
  const [feedback, setFeedback] = useState('');
  const [disabled, setDisabled] = useState(true);
  const csvFileInput = useRef();

  useEffect(() => {
    actions.getBatteryTypes();
    actions.getVehicleTypes();
    actions.getCommTypes();
  }, []);

  const onChangeBatteryType = (event) => setBattery_type(event.target.value);
  const onChangeVehicleType = (event) => setVehicle_type(event.target.value);
  const onChangeCommType = (event) => setComm_type(event.target.value);

  const onChangeCSV = () => {
    if (csvFileInput.current.files.length === 0) {
      setFeedback('');
      setDisabled(true);
      return;
    }
    const filename = csvFileInput.current.files[0].name;
    const csvExtension = filename.substr(-3).toLowerCase() === 'csv';
    setFeedback(!csvExtension ? "File does not end in 'csv'. Are you sure it's a csv file?" : '');
    setDisabled(false);
  };

  const onSubmitVehicleCSV = (event) => {
    setFeedback('');
    setDisabled(true);
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    addVehiclesFromCSV(data)
      .then((response) => {
        download(response, 'vehicle_import_results.csv');
        setFeedback('Import complete. See downloaded csv for details.');
        setDisabled(false);
      })
      .catch((error) => {
        setFeedback(error.message || 'There was a problem with your request.');
        setDisabled(false);
      });
  };

  return (
    <AuthenticatedPage>
      <div className="page-title">Add Vehicles From CSV</div>
      <form onSubmit={onSubmitVehicleCSV}>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="name">
            CSV File
          </label>
          <input type="file" ref={csvFileInput} name="vehicles" onChange={onChangeCSV} />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="vehicle_type">
            Vehicle Type
          </label>
          <select value={vehicle_type} onChange={onChangeVehicleType} name="vehicle_type">
            {vehicle_types.map((vehicle_type, index) => {
              return (
                <option key={`vehicle_type_${index}`} value={vehicle_type.id}>
                  {vehicle_type.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="battery_type">
            Battery Type
          </label>
          <select value={battery_type} onChange={onChangeBatteryType} name="battery_type">
            {battery_types.map((battery_type, index) => {
              return (
                <option key={`battery_type_${index}`} value={battery_type.id}>
                  {battery_type.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="comm_type">
            Integration Method
          </label>
          <select value={comm_type} onChange={onChangeCommType} name="comm_type">
            {comm_types.map((comm_type, index) => {
              return (
                <option key={`comm_type_${index}`} value={comm_type.id}>
                  {comm_type.provider} - {comm_type.protocol}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form_input_section">
          <input type="submit" disabled={disabled} />
        </div>
      </form>
      <p>{feedback}</p>
    </AuthenticatedPage>
  );
};

AddVehicles.propTypes = {
  actions: PropTypes.object,
  battery_types: PropTypes.array,
  vehicle_types: PropTypes.array,
  comm_types:    PropTypes.array,
};
const mapStateToProps = (state) => {
  const { battery_types, vehicle_types, comm_types } = state;
  return { battery_types, vehicle_types, comm_types };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ getBatteryTypes, getVehicleTypes, getCommTypes }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicles);
